/* CSS Document */

html, body {
  height: 100%;
}

body {
  background-color: #C9D3DF;
  color: #53657D;
  font-size: 16px;
  line-height: 1.4;
}

h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 0;
  float: left;
  font-weight: normal;
}

h2 {
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: 0;
}

.fs-5 {
  font-size: 1.25rem;
}

.grey {
  color: #53657D;
}

.grey-medium {
  color: #8393A7;
}

.grey-light {
  color: #ADB9C9;
}

.bigger {
 font-size: 1.5rem;
}

.grey-b {
 font-size: 1.5rem;
 color: #53657D;
}

.red {
  color: #D1335B;
}

.bg-white {
  background: white;
}

/* BREADCRUMB
------------------------------------------------------------------------------------
*/

.breadcrumb {
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: normal;
  color: #53657D;
  padding: 0;
  margin-bottom: 3rem;
}

.breadcrumb > li:first-child {
  text-transform: uppercase;
}

.breadcrumb a {
  color: #53657D;
}

.breadcrumb-item.active {
  color: #53657D;
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #A939B9;
  content: "\276F";
}

.breadcrumb .ttip {
  display: inline-block;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #FFFFFF;
  background-color: #778293;
  padding: 0.25rem;
  vertical-align: middle;
  padding: .375rem .75rem;
  border-radius: 0.25rem;
  margin-left: 0.5rem;
}

/* TLAČÍTKA
------------------------------------------------------------------------------------
*/
.btn {
  text-transform: uppercase;
}

.btn-green {
  color: #fff;
  background-color: #9DBF15;
  border-color: transparent;
}

.btn-green:hover {
  color: #fff;
  background-color: #B6DD19;
  border-color: transparent;
}

.btn-green:focus, .btn-green.focus {
  box-shadow: none;
}

.btn-green.disabled, .btn-green:disabled {
  color: #fff;
  background-color: #9CA86C;
  border-color: transparent;
}

.btn-purple {
  color: #fff;
  background-color: #A839BA;
  border-color: transparent;
}

.btn-purple:hover {
  color: #fff;
  background-color: #BA54CA;
  border-color: transparent;
}

.btn-purple:focus, .btn-purple.focus {
  box-shadow: none;
}

.btn-purple.disabled, .btn-purple:disabled {
  color: #fff;
  background-color: #8B7A8E;
  border-color: transparent;
}

.btn-blue {
  color: #fff;
  background-color: #0081D5;
  border-color: transparent;
}

.btn-blue:hover {
  color: #fff;
  background-color: #13A1FD;
  border-color: transparent;
}

.btn-blue:focus, .btn-blue.focus {
  box-shadow: none;
}

.btn-blue.disabled, .btn-blue:disabled {
  color: #fff;
  background-color: #4B83A7;
  border-color: transparent;
}

.btn-grey {
  color: #fff;
  background-color: #ADB9C9;
  border-color: transparent;
}

.btn-grey:hover {
  color: #fff;
  background-color: #CDD5DE;
  border-color: transparent;
}

.btn-grey:focus, .btn-grey.focus {
  box-shadow: none;
}

.btn-grey.disabled, .btn-grey:disabled {
  color: #fff;
  background-color: #DCE2E8;
  border-color: transparent;
}

.btn-orange {
  color: #fff;
  background-color: #FF9947;
  border-color: transparent;
}

.btn-orange:hover {
  color: #fff;
  background-color: #FFAC69;
  border-color: transparent;
}

.btn-orange:focus, .btn-orange.focus {
  box-shadow: none;
}

.btn-orange.disabled, .btn-orange:disabled {
  color: #fff;
  background-color: #E19C65;
  border-color: transparent;
}

input.form-control,
select.form-control  {
  margin-bottom: 0.5rem;
  background-color: #ECEFF4;
}

input.form-control:active, input.form-control:focus,
select.form-control:active, select.form-control:focus {
  background-color: #FFFFFF;
  box-shadow: none;
}

label {
  color: #ADB9C9;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.button-up {
  position: fixed;
  bottom: 30px;
  right: 60px;
  height: 60px;
  width: 60px;
  border-radius: 1rem;
  background: #C9D3DF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button-up:hover .chevron {
    transform: translateY(-4px) scale(1.04);
    transition-duration: 75ms;
}

/* HLAVNÍ MENU
------------------------------------------------------------------------------------
*/

#main-navbar {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background: white;
}

#main-navbar a {
  color: #53657D;
}

#main-navbar a:hover {
  color: #ADB9C9;
}

#main-navbar.navbar {
  display: block;
  padding: 1rem 0;
}

#main-navbar .navbar-nav .nav-item {
  padding: 1rem;
  text-transform: uppercase;
}

#main-navbar .navbar-nav .nav-link.active {
  background-image: linear-gradient(to right, #A939B9, #D1335B);
  color: rgba(255,255,255,0.9);
}

#main-navbar .nav-item.user {
  text-transform: none;
}

#main-navbar .user img {
  width: 30px;
  height: 30px;
}

#main-navbar .user img.photo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  padding: 5px;
  border-radius: 50%;
  background-image: linear-gradient(to right, #A939B9, #D1335B);
  margin-bottom: 0.5rem;
  margin-right: 0;
}

/* HLAVNÍ OBSAH
------------------------------------------------------------------------------------
*/

main {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

main > div {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background: white;
  padding: 1rem;
  margin-bottom: 1.5rem
}

/* HLAVNÍ OBSAH - TABULKY
------------------------------------------------------------------------------------
*/

.tabulka {
  margin-top: 3rem;
  text-align: center;
}

.tabulka .row {
  margin-left: 0;
  margin-right: 0;
  border-bottom: 1px solid #EBEFF3;
  align-items: center;

}

.tabulka > :last-child {
  border-bottom: none;
}

.tabulka .row>div {
  padding-left: 0;
  padding-right: 0;
  align-items: center;
}

.tabulka .row>div.sm-p-l-25 {
  padding-left: 25px;
}

.tabulka .row>:nth-child(-n+2) {
  text-align: left;
}

.tabulka .row.header {
  border-bottom: 1px solid #8393A7;
  padding-bottom: 0.6rem;
}

.tabulka .row.header>:nth-child(2) {
  text-align: center;
}

.tabulka .row.user {
  border-top: 1px solid #8393A7;
  background-color: #F7F9FA;
  position: relative;
}

.tabulka .row .show-hide {
  cursor: pointer;
}

.tabulka .photo.show-hide img {
  margin-left: 15px;
}

.tabulka .row div.ico-show-hide svg {
  margin-top: 35px;
}

.tabulka .row div.name {
  padding-left: 1rem;
}

.tabulka .row div.state, .tabulka .row div.switches {
  color: #ADB9C9;
  display: flex;
  justify-content: space-around;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tabulka .row div.buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  visibility: visible;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tabulka .row:hover div.buttons {
  visibility: visible;
  border-left: none;
}

.tabulka .row div.buttons .display-user  {
  visibility: hidden;
}

.tabulka .row:hover div.buttons .display-user {
  visibility: hidden;
}

.tabulka .row.user div.buttons .display-user  {
  visibility: visible;
}

.tabulka .row.user:hover div.buttons .hide-user {
  visibility: hidden;
}

.tabulka input.form-control, .tabulka select.form-control,
.tabulka .form-group, .tabulka label {
  margin-bottom: 0;
}

.tabulka .kartarka.inactive .switches .hide {
  visibility: hidden;
}

.tabulka .state img {
  margin-bottom: 0.25rem;
}

#persona-management .tabulka {
  margin-left: -15px;
  margin-right: -15px;
}

#persona-management .tabulka .row {
  padding-left: 15px;
  padding-right: 15px;
}

#persona-management .buttons img {
  height: 25px;
  width: 25px;
}

/* PŘIHLAŠOVACÍ STRÁNKA
------------------------------------------------------------------------------------
*/

#sign-in {
  display: flex;
  align-items: center;
  height: 100%;
}

#sign-in form {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background: white;
  width: 100%;
  max-width: 400px;
  margin: auto;
  text-align: center;
  border-radius: 10px;
  padding: 2rem 3rem;
}

#sign-in h1 {
  float: none;
  margin-bottom: 1.5rem;
  text-transform: none;
}

#sign-in p {
  margin-top: 1rem;
  margin-bottom: 0;
}

#sign-in input {
  height: auto;
  padding: 0.6rem;
  text-indent: 37px;
}

#sign-in input#inputEmail {
  background-image: url(/img/envelope.png);
  background-repeat: no-repeat;
  background-position: 0.7rem 0.7rem;
  background-size: auto 20px;
}

#sign-in input#inputPassword {
  background-image: url(/img/lock.png);
  background-repeat: no-repeat;
  background-position: 0.8rem 0.5rem;
  background-size: auto 25px;
}

.btn-gradient {
  color: #fff;
  background-image: linear-gradient(to right, #A939B9, #D1335B);
  border-color: transparent;
  border-radius: 38px;
  padding: 0.6rem 2rem;
}

.btn-gradient:hover {
  color: #fff;
  background-image: linear-gradient(to right, #A939B9, #A939B9);
  border-color: transparent;
}

.btn-gradient:focus, .btn-gradient.focus {
  box-shadow: none;
}

.btn-gradient.disabled, .btn-gradient:disabled {
  color: #fff;
  background-image: linear-gradient(to right, #994FA3, #B45069);
  border-color: transparent;
}

/* DASHBOARD - USER
------------------------------------------------------------------------------------
*/

#user-dashboard .user-status {
  box-shadow: none;
  background: transparent;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.2rem;
  line-height: 1.25rem;
}

#user-dashboard .user-status .status-main {
  text-transform: uppercase;
}

#user-dashboard .user-status .status-detail {
  font-size: 0.9rem;
}

#user-dashboard .user-status>div>div {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background: white;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
  color: #8393A7;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

#user-dashboard .user-status img {
  float: left;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  margin-right: 0.7rem;
}

#user-dashboard .user-status .label {
  color: #ADB9C9;
  font-size: 0.8rem;
}

#user-dashboard .user-status .offline img {
  background-image: linear-gradient(to right, #FF9947, #D1335B);
}

#user-dashboard .user-status .online img {
  background-image: linear-gradient(to right, #FFD92D, #03BD5B);
}


 #user-dashboard .user-status .phone {
  font-size: 1.7rem;
  padding: 2rem 0;
  display: block;
}

#user-dashboard .web-detail {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background: white;
  padding: 1rem;
  margin-bottom: 1.5rem
}

#user-dashboard .photo {
  display: flex;
}

#user-dashboard .photo img {
  width: 90px;
  height: 90px;
  margin-right: 1rem;
}

#user-dashboard .tabulka .row {
  border-bottom: none;
  line-height: 1.6rem;
}


/* DASHBOARD - SOUHRNNÝ PŘEHLED
------------------------------------------------------------------------------------
*/

#summary-overview .web-status {
  box-shadow: none;
  background: transparent;
  padding: 0;
  margin-bottom: 0;
}

#summary-overview .web-status>div>div {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background: white;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
  color: #8393A7;
}

#summary-overview .web-status img {
  float: left;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  margin-top: 2px;
  margin-right: 0.7rem;
}

#summary-overview .web-status .offline img {
  background-image: linear-gradient(to right, #FF9947, #D1335B);
}

#summary-overview .web-status .online img {
  background-image: linear-gradient(to right, #FFD92D, #03BD5B);
}

#summary-overview .web-status .count{
  font-size: 1.2rem;
  font-weight: bold;
}

#summary-overview .web-status .online .count, #summary-overview .web-status .online .web {
  color: #9DBF15;
}

#summary-overview .web-status .offline .count, #summary-overview .web-status .offline .web {
  color: #D1335B;
}

#summary-overview .web-detail {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background: white;
  padding: 1rem;
  margin-bottom: 1.5rem
}

/* KARTÁŘKY
------------------------------------------------------------------------------------
*/

#oracle .tabulka .row>:nth-child(2) {
  text-align: center;
}

#oracle .tabulka .row {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

/* DETAIL WEBU
------------------------------------------------------------------------------------
*/

#web .tabulka .row > :first-child {
  text-align: center;
}

#web .tabulka .row.header > :first-child, #web .tabulka .row div.name {
  text-align: left;
}

/* UDÁLOSTI V SYSTÉMU
------------------------------------------------------------------------------------
*/

#audit-log .table-sm td,
#audit-log .table-sm th {
  padding: .3rem .5rem;
}

#events .form-row {
  align-items: flex-end;
}

#events .form-row button {
  margin-bottom: 0.5rem;
}


#events .form-group {
  margin-bottom: 0;
}

#events table {
  width: 100%;
  margin-top: 3rem;
}

#events th {
  font-weight: normal;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid #8393A7;
}

#events td {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid #EBEFF3
}

#events tr:nth-child(even) {
  background-color: #F5F7F9;
}

#events td:first-child {
  padding-left: 0.6rem;
}

#events table img {
  height: 18px;
  width: 18px;
}

/* OBRÁZKY A TLAČÍTKA
------------------------------------------------------------------------------------
*/

img.ico-small {
  width: 15px;
  height: 15px;
}

img {
  width: 30px;
  height: 30px;
}

.photo img {
  width: 70px;
  height: 70px;
  float: left;
  object-fit: cover;
  border-radius: 1rem;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}

div.photo-big {
  display: flex;
  height: 288px;
  background-color: grey;
  margin-top: 0.5rem;
}

img.photo-big {
  object-fit: cover;
  height: 100%;
  width: 100%;
}


.custom-switch {
  padding-left: 0;
}

.custom-control {
  padding-left: 0;
}

.custom-switch .custom-control-label::before {
  position: relative;
  width: 46px;
  height: 30px;
  left: 0;
  top: 0;
  border-radius: 34px;
  background-color: #C9D3DF;
  border: 0;
  cursor: pointer;
  margin-bottom: 5px;
}

.custom-switch .custom-control-label::after {
  top: 4px;
  left: 3px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #FFFFFF;
  cursor: pointer;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #FFFFFF;
  -webkit-transform: translateX(18px);
  transform: translateX(18px);
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #C9D3DF;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  border-color: #C9D3DF;
  background-color: #C9D3DF;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #FFFFFF;
  background-color: #03BD5B;
}

/* RESPONZIVNÍ - MEDIA
------------------------------------------------------------------------------------
*/


@media (min-width: 768px) {
  #user-dashboard .user-status img {
    width: 45px;
    height: 45px;
    padding: 10px;
    }
  #summary-overview .web-status img {
    width: 45px;
    height: 45px;
    padding: 10px;
    }
  .tabulka .row div.buttons {
    visibility: hidden;
    margin-top: 0;
    margin-bottom: 0;
  }
  .tabulka .row div.state, .tabulka .row div.switches {
    margin-top: 0;
    margin-bottom: 0;
  }
  .tabulka .row>div.sm-p-l-25 {
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1200px;
  }
  input {
    margin-bottom: 0;
    }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1350px;
    }
  #main-navbar .user img.photo {
    width: 140px;
    height: 140px;
    }
}

/* IMAGE UPLOAD DIALOG
------------------------------------------------------------------------------------
*/
.imageUploadDialog div.preview {
  text-align: center;
  margin: 5px 15px;
  border: 1px solid gray;
}

.imageUploadDialog div.preview img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}


/* OVERLAY */
.overlayWrapper {
  position: relative;
}

.overlayContent {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  /*dim the background*/
  top:0;
  left:0;
  width:100%;
  height:100%;
  color:black;
  text-align: center;
  z-index: 2;
}
